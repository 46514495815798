.header {
  width: 100%;
  height: 60px;
  min-height: 60px;
}
.header_inner {
  height: 60px;
  min-height: 60px;
  max-width: 1140px;
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin: 0 auto !important;
  display: flex;
  padding: 0 20px;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}
.header_logo {
  width: 200px;
}
.header_logo_inner {
  width: 150px;
}
.header_selector {
  width: 350px;
}
.title {
  width: 585px;
}
#return-to-fruition:hover {
  color: white !important;
}

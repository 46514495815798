.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: red;
  color: white;
  text-align: center;
}

.footer-left {
  background: #004e16;
  min-height: 350px;

  .ellipse-1 {
    position: absolute;
    left: 0%;
    right: 79.01%;
    top: 20.25%;
    bottom: 0%;

    background: linear-gradient(180deg, #0e6304 0%, rgba(14, 99, 4, 0) 100%);
    opacity: 0.34;

    border-top-right-radius: 100%;
  }

  .ellipse-2 {
    position: absolute;
    left: 0%;
    right: 69.06%;
    top: 9.86%;
    bottom: 0%;

    background: linear-gradient(180deg, #0e6304 0%, rgba(14, 99, 4, 0) 100%);
    opacity: 0.34;

    border-top-right-radius: 100%;
  }

  .footer-nav {
    .text {
      color: white;
      font-size: 14px;
      padding: 10px !important;
    }
    
    .first-link {
      padding-left: 0px !important;
    }
  
  }
  
  .line {
    margin-top: 0px;
    height: 0px;
    border: none;
    border-top: 1px solid rgb(121, 121, 121);
  }

  .center-button {
    font-size: 14px;
    padding-left: 0px;
  }

  .bottom {
    color: rgb(171, 171, 171);
    font-size: 14px;
  }
}

.footer-right {
  min-height: 350px;

  .bottom {
    background: #333333;

    .text {
      font-size: 18px;
    }

    .button {
      font-size: 14px;
      background: #5c9817;
      border-radius: 82px;
      padding: 8px 35px;
    }
  }
}


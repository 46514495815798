// change the theme
$theme-colors: (
  "fruition": #65A619
);

// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";

@media print {
  .print-text-success {
    color: #28a745;
  }
  .print-text-warning {
    color: #ffc107;
  }
  .print-text-danger {
    color: #dc3545;
  }
}

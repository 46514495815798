@media (min-width: 991.98px) {
  .summary-sticky {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
  }
}

@media (min-width: 991.98px) {
  .season-overview-table-filter-sticky {
    position: sticky;
    top: 480px;
    z-index: 1;
    background-color: white;
  }
}

@media (min-width: 991.98px) {
  .season-overview-table-sticky {
    position: sticky;
    top: 520px;
    z-index: 1;
  }
}


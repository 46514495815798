@page {
  size: auto;
}

@media all {
  .page-break {
    display: none;
  }
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: always;
  }

  .hide-on-print {
    display: none;
  }
}

/*mobile menu*/

/*
 Default styles + Mobile first
 Offscreen menu style
*/
.header-mobile .header_inner {
  padding: 0 0 0 20px;
  max-width: 100%;
}
.header-mobile .header_logo {
  max-width: 80%;
  width: 150px;
}
.mobile-menu {
  background: #fff;
  position: absolute;
  display: block;
  left: 100%;
  top: 0;
  height: 100%;
  overflow-y: scroll;
  overflow-x: visible;
  transition: left 0.3s ease;
  width: 100%;
  max-width: 100vw;
  padding: 0;
  z-index: 999;
  min-height: 100%;
  /*enable momentum scrolling*/
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.mobile-menu-header {
  background: #8999a2;
  height: 60px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  padding: 0 0 0 20px;
  font-size: 18px;
  justify-content: space-between;
}
.mobile-menu-header a,
.mobile-menu-header a:hover,
.mobile-menu-header a:active,
.mobile-menu-header a:focus {
  color: #fff;
  text-decoration: none;
}
.mobile-menu-group {
  line-height: 1em;
  margin-left: 25px;
  margin-bottom: 25px;
}
.mobile-menu ul {
  list-style: none;
  display: block;
  width: auto;
}
.mobile-menu ul ul {
  padding-left: 15px;
}
.mobile-menu-group li a {
  padding: 8px 0;
}
.mobile-menu li {
  line-height: 38px;
  font-size: 18px;
}
li.menu-item-title {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 6px;
}
.menu-item-sub-title {
  font-weight: bold;
  color: #646d71;
}

.menu-item-sub-title {
}

.mobile-menu li a {
  color: #06c;
}

/* Hamburger button styling */
.menu-toggle {
  color: #ffffff;
  font-size: 2em;
  padding: 20px;
}

.menu-toggle:hover,
.menu-toggle:focus {
  color: #fff;
}
.mobile-menu .menu-close {
  color: #fff;
  font-size: 2em;
  padding: 20px;
}

.mobile-menu-tools {
  background-color: #8999a2;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.mobile-menu-tools a,
.mobile-menu-tools a:focus,
.mobile-menu-tools a:active,
.mobile-menu-tools a:hover {
  width: auto;
  display: block;
  text-decoration: none;
  padding: 25px;
  color: #fff;
}

/*
 On small devices, allow it to toggle...
*/
/*
 :target for non-JavaScript
 [aria-expanded] will be used if/when JavaScript is added to improve interaction, though it's completely optional.
*/
.mobile-menu:target,
.mobile-menu[aria-expanded='true'] {
  display: block;
  left: 0;
  outline: none;
  transition: left 0.3s ease;
}

.mobile-menu:target .menu-close,
.mobile-menu[aria-expanded='true'] .menu-close {
  z-index: 1001;
}

.mobile-menu:target ul,
.mobile-menu[aria-expanded='true'] ul {
  position: relative;
  z-index: 1000;
}

/* 
 We could us `.mobile-menu:target:after`, but
 it wouldn't be clickable.
*/
.mobile-menu:target + .backdrop,
.mobile-menu[aria-expanded='true'] + .backdrop {
  position: absolute;
  display: block;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 998;
  background: #000;
  background: rgba(0, 0, 0, 0.85);
  cursor: default;
  transition: right 0.3s ease;
}

@supports (position: fixed) {
  .mobile-menu,
  .mobile-menu:target + .backdrop,
  .mobile-menu[aria-expanded='true'] + .backdrop {
    position: fixed;
  }
}

/*side bar is open*/

html.menu-open {
  position: fixed;
  /* 
     It is the main content that is positioned. 
     This is the crux of the implementation. The rest is all sugar.

     Cons: the body will scroll to the top, losing your user's scroll position
    */

  /* prevents resizing from its original full-screen width */
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  overflow: hidden;
}
